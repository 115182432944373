import { Controller } from "stimulus";
import $ from "jquery";
import "jquery-datetimepicker";

export default class extends Controller {
  static targets = ["eventDate"];

  connect() {
    $.datetimepicker.setLocale("pl");
    $(this.eventDateTargets).datetimepicker({
      step: 60,
      dayOfWeekStart: 1,
      format: "Y-m-d H:i",
    });
  }
}
