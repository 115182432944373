import { Controller } from "stimulus";
export default class extends Controller {
  connect() {
    setTimeout(() => this.fetchStatus(), 1000);
  }

  fetchStatus() {
    const purchaseId = this.element.getAttribute("data-purchase-id");
    fetch(`/payu_url/${purchaseId}`)
      .then((response) => response.json())
      .then((d) => {
        if (d.status === "ok") {
          window.location = d.redirect_uri;
        } else if (d.status == "paid") {
          window.location = "/";
        } else {
          setTimeout(() => this.fetchStatus(), 1000);
        }
      });
  }
}
