import { Controller } from "stimulus";
import $ from "jquery";
import "jquery-datetimepicker";

export default class extends Controller {
  static targets = ["validFromLesson"];

  connect() {
    $.datetimepicker.setLocale("pl");
    $(this.validFromLessonTarget).datetimepicker({
      step: 30,
      dayOfWeekStart: 1,
      format: "Y-m-d H:i",
    });
  }
}
