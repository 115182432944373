import { Controller } from "stimulus";
import Trix from "trix";

import { getParentControllerForIdentifier } from "../lib/utils";

export default class extends Controller {
  static targets = ["code", "videoEmbed"];

  connect() {
    this.getParentControllerForIdentifier = getParentControllerForIdentifier.bind(
      this
    );
  }

  dismissVideoModal() {
    this.videoEmbedTarget.classList.add("hidden");
  }

  showVideoModal() {
    this.videoEmbedTarget.classList.remove("hidden");
  }

  addVideo() {
    const parentController = this.getParentControllerForIdentifier("trix");
    const vimeoString = this.codeTarget.value;
    const vimeoId = /([0-9])+/.exec(vimeoString)[0];

    const vimeoUrl = new URL(vimeoString);

    let vimeoPass = vimeoUrl.searchParams.get("h");
    // if (vimeoPass === null) {
    // }

    let embedCode;
    if (vimeoPass !== "" && vimeoPass !== null) {
      embedCode = `
        <div class="row" style="height: 600px">
          <div class="col-md-12">
            <iframe src="https://player.vimeo.com/video/${vimeoId}?h=${vimeoPass}" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
        </div>`;
    } else {
      embedCode = `
        <div class="row" style="height: 600px">
          <div class="col-md-12">
            <iframe src="https://player.vimeo.com/video/${vimeoId}" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
        </div>`;
    }

    fetch("/embeds", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ content: embedCode }),
    })
      .then((response) => response.json())
      .then((video) => {
        let attachment = new Trix.Attachment({
          sgid: video.sgid,
          content: embedCode,
        });
        parentController.editorTarget.editor.insertAttachment(attachment);
        this.dismissVideoModal();
      });
  }
}
