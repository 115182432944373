import { Controller } from "stimulus";
import Trix from "trix";

import { getParentControllerForIdentifier } from "../lib/utils";

export default class extends Controller {
  static targets = ["code", "videoEmbed"];

  connect() {
    this.getParentControllerForIdentifier = getParentControllerForIdentifier.bind(
      this
    );
  }

  dismissVideoModal() {
    this.videoEmbedTarget.classList.add("hidden");
  }

  showVideoModal() {
    this.videoEmbedTarget.classList.remove("hidden");
  }

  addVideo() {
    const parentController = this.getParentControllerForIdentifier("trix");
    const vimeoId = this.codeTarget.value;

    const embedCode = `
    <div class="row" style="height: 600px">
      <div class="col-md-8">
        <iframe src="https://vimeo.com/event/${vimeoId}/embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen width="100%" height="100%"></iframe>
      </div>
      <div class="col-md-4">
        <iframe src="https://vimeo.com/event/${vimeoId}/chat/" width="100%" height="100%" frameborder="0"></iframe>
      </div>
    </div>`;

    fetch("/embeds", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ content: embedCode }),
    })
      .then((response) => response.json())
      .then((video) => {
        let attachment = new Trix.Attachment({
          sgid: video.sgid,
          content: embedCode,
        });
        parentController.editorTarget.editor.insertAttachment(attachment);
        this.dismissVideoModal();
      });
  }
}
