import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "voucherCode",
    "price",
    "label",
    "visibleCode",
    "voucherValue",
    "discountRow",
    "checkValidityButton",
    "clearButton",
    "purchaseButton",
  ];

  connect() {
    this.checkValidity(new Event("build"), false);
  }

  clearCode(event) {
    event.preventDefault();

    this.voucherCodeTarget.value = "";

    this.checkValidity(event, false);
  }

  checkValidity(event, showMessage = true) {
    event.preventDefault();
    const codeValue = this.voucherCodeTarget.value;

    let url = "check_code_validity";
    if (!window.document.location.pathname.endsWith("/purchases/new")) {
      url = "purchases/" + url;
    }

    fetch(url, {
      body: JSON.stringify({ code: codeValue }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          if (showMessage) {
            window.toastr.success(`Zastosowano kod rabatowy ${codeValue}`);
          }
          this.voucherCodeTarget.setAttribute("readonly", true);
          this.show(this.discountRowTarget);
          this.show(this.clearButtonTarget);
          this.hide(this.checkValidityButtonTarget);
          this.labelTarget.innerHTML = "Cena po rabacie";
          this.visibleCodeTarget.innerHTML = response.voucher_code;
          this.voucherValueTarget.innerHTML = response.discount_value;
        } else {
          if (showMessage) {
            window.toastr.error(`Niepoprawny kod rabatowy ${codeValue}`);
          }
          this.voucherCodeTarget.removeAttribute("readonly");
          this.voucherCodeTarget.value = "";
          this.hide(this.discountRowTarget);
          this.hide(this.clearButtonTarget);
          this.show(this.checkValidityButtonTarget);
          this.labelTarget.innerHTML = "Cena";
        }
        this.priceTarget.innerHTML = response.price_with_discount;
        if (response.price_with_discount === "0,00 zł") {
          this.purchaseButtonTarget.value = "Uzyskaj dostęp za darmo";
          this.purchaseButtonTarget.dataset.disableWith =
            "Uzyskaj dostęp za darmo";
        } else {
          this.purchaseButtonTarget.value = "Zamów i przejdź do płatności PayU";
          this.purchaseButtonTarget.dataset.disableWith =
            "Zamów i przejdź do płatności PayU";
        }
      });
  }

  hide(element) {
    element.setAttribute("style", "display:none !important");
  }

  show(element) {
    element.style.display = "block";
  }
}
