import { Controller } from "stimulus";
import Trix from "trix";

import { getParentControllerForIdentifier } from "../lib/utils";

export default class extends Controller {
  static targets = ["code", "htmlEmbed"];

  connect() {
    this.getParentControllerForIdentifier = getParentControllerForIdentifier.bind(
      this
    );
  }

  dismissHtmlModal() {
    this.htmlEmbedTarget.classList.add("hidden");
  }

  showHtmlModal() {
    this.htmlEmbedTarget.classList.remove("hidden");
  }

  addHtml() {
    const parentController = this.getParentControllerForIdentifier("trix");
    const embedCode = this.codeTarget.value;

    fetch("/embeds", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ content: embedCode }),
    })
      .then((response) => response.json())
      .then((html) => {
        let attachment = new Trix.Attachment({
          sgid: html.sgid,
          content: embedCode,
        });
        parentController.editorTarget.editor.insertAttachment(attachment);
        this.dismissHtmlModal();
      });
  }
}
