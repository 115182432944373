import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["mainNav"];

  connect() {
    this.scroll();
  }

  scroll() {
    if (window.pageYOffset > 50) {
      this.mainNavTarget.classList.add("navbar-scrolled");
    } else {
      this.mainNavTarget.classList.remove("navbar-scrolled");
    }
  }
}
