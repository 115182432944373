import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["extraFieldsField"];

  connect() {
    // const parsed = JSON.parse(this.extraFieldsFieldTarget.value);
  }

  // snippet({ name, type, html_name }) {

  // }
}
