import { Controller } from "stimulus";
import Trix from "trix";
import $ from "jquery";
import "jquery-datetimepicker";
import { v4 as uuidv4 } from "uuid";

import { getParentControllerForIdentifier } from "../lib/utils";

export default class extends Controller {
  static targets = [
    "meetingStart",
    "meetingFinish",
    "meetingEmbed",
    "zoomLink",
    "lessonId",
    "meetingUuid",
  ];

  connect() {
    this.getParentControllerForIdentifier = getParentControllerForIdentifier.bind(
      this
    );
    $.datetimepicker.setLocale("pl");
    $(this.meetingStartTarget).datetimepicker({
      step: 30,
      dayOfWeekStart: 1,
      format: "Y-m-d H:i",
    });
    $(this.meetingFinishTarget).datetimepicker({
      step: 30,
      dayOfWeekStart: 1,
      format: "Y-m-d H:i",
    });
    this.meetingUuidTarget.value = uuidv4();
  }

  showMeetingModal() {
    this.meetingEmbedTarget.classList.remove("hidden");
  }

  dismissMeetingModal() {
    this.meetingEmbedTarget.classList.add("hidden");
  }

  addMeeting() {
    const parentController = this.getParentControllerForIdentifier("trix");
    const meetingStart = this.meetingStartTarget.value;
    const meetingFinish = this.meetingFinishTarget.value;
    const zoomLink = this.zoomLinkTarget.value;
    const lessonId = this.lessonIdTarget.value;
    const meetingUuid = this.meetingUuidTarget.value;

    fetch("/meetings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lesson_id: lessonId,
        start_time: meetingStart,
        end_time: meetingFinish,
        zoom_link: zoomLink,
        meeting_uuid: meetingUuid,
      }),
    })
      .then((response) => response.json())
      .then((meeting) => {
        let attachment = new Trix.Attachment({
          sgid: meeting.sgid,
          content: `<div class="future-meeting">
        Tutaj użytkownik zobaczy spotkanie<br />
        aktywne od
        ${meetingStart}
        do
        ${meetingFinish}</div>`,
        });

        parentController.editorTarget.editor.insertAttachment(attachment);
        this.dismissMeetingModal();
      });
  }
}
