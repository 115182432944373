import { Controller } from "stimulus";
export default class extends Controller {
  connect() {
    setTimeout(() => this.fetchStatus(), 1000);
  }

  fetchStatus() {
    const purchaseId = this.element.getAttribute("data-purchase-id");

    fetch(`/status/${purchaseId}`)
      .then((response) => response.json())
      .then((d) => {
        if (d.status === "paid") {
          window.location = `/success/${purchaseId}`;
        } else {
          setTimeout(() => this.fetchStatus(), 1000);
        }
      });
  }
}
