import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["textArea"];

  submitted(event) {
    if (this.textAreaTarget.value.length == 0) {
      event.preventDefault();
      window.toastr.error("Komentarz nie może być pusty");
      return false;
    }
    return true;
  }
}
