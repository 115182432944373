import { Controller } from "stimulus";
import "bootstrap";
import $ from "jquery";

export default class extends Controller {
  static targets = ["sidebarCollapse", "sidebar", "content"];

  connect() {
    $("[data-toggle='tooltip']").tooltip();
  }

  toggleSidebar() {
    this.sidebarTarget.classList.toggle("active");
    this.contentTarget.classList.toggle("active");
    this.sidebarCollapseTarget.classList.toggle("unrolled");
    this.sidebarCollapseTarget.classList.toggle("rolled");
  }
}
